<template>
  <b-container>
    <b-row>
      <b-col md="12" v-if="videos.length <= 0">
        <h3 class="text-center text-muted mt-5">No hay videos cargados...</h3>
      </b-col>
      <b-col md="6" v-for="(item, index) in videos" :key="index">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ item.title }}</h4>
          </template>
          <template v-slot:body>
            <b-embed
              type="iframe"
              aspect="16by9"
              :src="'https://www.youtube.com/embed/' + item.youtube_code"
              allowfullscreen
            ></b-embed>
            <hr />
            <span>{{ item.content }}</span>
          </template>
        </iq-card></b-col
      >
    </b-row>
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
export default {
  mounted() {
    this.$store.commit("SET_BREADCRUMB", this.items);
    core.index();
    this.getVideos();
    console.log(this.videos);
  },
  data() {
    return {
      documento: require("@/assets/doc/manual.pdf"),
      items: [
        {
          html: 'Inicio',
          to: "/",
        },
        {
          text: "Ayuda",
          active: true,
        },
      ],
    };
  },
  methods: {
    ...mapActions({ getVideos: "help/get" }),
  },
  computed: {
    ...mapGetters({ videos: "help/getVideos" }),
  },
};
</script>
<style>
</style>
